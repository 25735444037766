import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";

import {
  Section,
  Container,
  Title,
  Text,
  Button,
  Markdown,
  YoutubeIcon,
  getProperty,
  useFormatterDateFromTo,
} from "@btc/shared";

import * as styles from "./intro.module.scss";

export const Intro = ({ data }) => {
  const title = getProperty(data, "title");
  const content = getProperty(data, "content");

  const dateTitle = getProperty(data, "event.contents.date.title");
  const dateContent = useFormatterDateFromTo(
    getProperty(data, "event.date.from"),
    getProperty(data, "event.date.to")
  );

  const locationTitle = getProperty(data, "event.contents.location.title");
  const locationContent = getProperty(data, "event.location.name");

  const linkUrl = getProperty(data, "link.url");
  const linkText = getProperty(data, "link.text");

  return (
    <Section id="intro" size="sm">
      <Container>
        <div className={styles.content}>
          <div className={styles.container}>
            <div className={styles.text}>
              <Title>{title}</Title>
              <Markdown content={content} />
            </div>
            {linkUrl && (
              <div className={styles.link}>
                <Button
                  wrapper="a"
                  href={linkUrl}
                  target="_blank"
                  icon={<YoutubeIcon className={styles.icon} />}>
                  {linkText}
                </Button>
              </div>
            )}
          </div>
        </div>

        {/*<div className={styles.event}>
          <div className={styles.date}>
            <Title wrapper="h3" type="special">
              {dateTitle}
            </Title>
            <Text size="s5" weight="bold">
              {dateContent}
            </Text>
          </div>
          <div className={styles.location}>
            <Title wrapper="h3" type="special">
              {locationTitle}
            </Title>
            <Text size="s5" weight="bold">
              {locationContent}
            </Text>
          </div>
        </div>*/}
      </Container>
    </Section>
  );
};

Intro.defaultProps = {
  data: {},
};

Intro.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    event: PropTypes.object.isRequired,
    link: PropTypes.object.isRequired,
  }).isRequired,
};

export const query = graphql`
  fragment HomeEventIntroFragment on MarkdownRemark {
    frontmatter {
      event {
        title
        contents {
          date {
            title
          }
          location {
            title
          }
        }
        location {
          name
        }
        date {
          from
          to
        }
      }
    }
  }
`;
