import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

import {
  Section,
  ContainerToLG,
  Text,
  Title,
  Markdown,

  ArrowIcon,
  getProperty,
  useMatchMedia,
  MD,
} from "@btc/shared";


import { Languages } from "theme";

import { Marquee } from "../common/marquee";

import * as styles from "./teaser.module.scss";
import {LogoLarge} from "../../theme";

const MEDIA_QUERIES = [`(min-width:${MD}px)`];

export const Teaser = ({ data }) => {
  const [isMD] = useMatchMedia(MEDIA_QUERIES);

  const title = getProperty(data, "title");
  const logos = getProperty(data, "logos") || [];
  const image = getProperty(data, "image.childImageSharp.gatsbyImageData");
  const marquee = getProperty(data, "marquee");

  return (
    <Section color="black">
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <div className={styles.banner}>
            <Text transform="uppercase" color="white" weight="light" size="s5">
              {title}
            </Text>
            <div className={styles.logos}>
              {logos.map((item, index) =>
                <div key={index} className={styles.logo}>
                  <GatsbyImage
                  alt={getProperty(item, "label")}
                  image={getProperty(item, "image.childImageSharp.gatsbyImageData")} />
                </div>
              )}
            </div>
          </div>

          <div className={styles.languages}>
            <ContainerToLG>
              <Languages />
            </ContainerToLG>
          </div>

          {isMD && (
            <a href="#intro" className={styles.arrow}>
              <ArrowIcon className={styles.icon} />
            </a>
          )}
        </div>

        <div className={styles.bottom}>
          <Marquee>{marquee}</Marquee>
        </div>

        <div className={styles.background}>
          <GatsbyImage image={image} alt={title} className={styles.image} />
        </div>
      </div>
    </Section>
  );
};

Teaser.defaultProps = {
  data: {},
};

Teaser.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    sponsor: PropTypes.shape({
      url: PropTypes.string.isRequired,
      image: PropTypes.object.isRequired,
    }).isRequired,
  }).isRequired,
};
